import React from 'react';
import Quote from './quote';
import  './header.css';
import Hr from './hr';

const binaryQuote = '01110011 01101000 01101001 01110100 00100000 01100010 01101001 01101110 01100001 01110010 01111001';

const Header = (props) => {
    return (
    <header>
    <h1 style={props.style}>UNICORN SHIT</h1>
    <Quote quote={binaryQuote}/>
    <Hr />
    </header>
    )
}

export default Header;